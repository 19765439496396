import React, { Component } from "react";
const $ = window.$;
class ModalOfVersions extends Component {
  state = {
    versions: this.props.versions,
  };
  
  makeSearchMobile = (changehandle)=>{
   
    
    changehandle("mobile");
    $("#modalOfSearch").modal("hide");
  }
  render() {
    const {trans}=this.props;
    return (
      <div
        className="modal fade"
        id="modalOfSearch"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-notify modal-info" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <p className="heading lead headFont">{trans["dzebna"]}</p>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className="white-text">
                  &times;
                </span>
              </button>
            </div>

            <div className="modal-body">
              <div className="text-center">
                <div className="mt-3">
                <select  className="browser-default custom-select custom-select-md mb-3" id = "mobilebiblebook" >      
                        {this.props.bibleNames.map((b,ind) => {
                          return   <option value={ind+1} key={b}  >{b}</option> })
                            } 
                </select>
                </div>
                <div className="mt-3">
                  <div className="md-form form-md  input-with-post-icon">
                    <i className="fas fa-search input-prefix"></i>
                    <input
                      type="text"
                      id="bibleTextSearchMobile"
                      className="form-control"
                    />
                    <label htmlFor="bibleTextSearchMobile">{trans["dzebna"]} ...</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer justify-content-center">
              <a
                type="button"
                onClick={()=>this.makeSearchMobile(this.props.hendleInTextSearch)}
                className="btn btn-info headFont"
              >
                {trans["dzebna"]}
              </a>
              <a
                type="button"
                className="btn btn-outline-info headFont waves-effect"
                data-dismiss="modal"
              >
                {trans["close"]}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalOfVersions;
