import React, { Component } from 'react';
import Header from '../readbible/header';
import Footer from '../readbible/footer'; 
import Config from "../../settings/config.json";
import axios from "axios";
import {Link} from 'react-router-dom';

const cover = Config.coverurl;
class IndexPage extends Component {
    httpCon = Config.httpconnection;
    state = { 
        language: this.props.match.params.language !=="" && this.props.match.params.language  ? this.props.match.params.language : "geo",
        versions: [],
        vers: [],
        languages: [],
        trans:[],
        langsm: [],
     }
     
     async componentDidMount() {
       
        let { data } = await axios.get(
            this.httpCon + "?formain=1&language=" + this.state.language 
        )
       //console.log(data);
        const versions = data.versions;
        const vers = data.vers;
        const trans = data.trans
        const languages = data.languages;
        const langsm = data.langsm;
        this.setState({versions,vers,languages,trans,langsm})
        }
        iternumber = 0;
        linkwigni = (wigni,count)=>{
            const lang = this.state.language ;
            if(lang === "geo" || lang === "georgian"){
                if(count === 6 ||  count === 8)return 43;
            }
            if(lang === "ossetian" || lang === "os"){
                return 43;
            }
            if(lang === "abkhazian" || lang === "ab"){
                return 43;
            }
            return wigni;
        }
         
    render() { 
        return ( 
            <React.Fragment>
                <Header languages ={this.state.languages} trans={this.state.trans} langsm ={this.state.langsm} language = {this.state.language} />
                <div className="container homePageIndex">
                    {
                        
                    this.state.versions.map((t,ind)=>{   
                       const {language, languages, langsm} = this.state;
                        let index = ind +1; 
                        let coverlang=language;            
                        if(index==7 && (language == "geo" || language == "georgian"))return; 
                          if (languages.indexOf(language) >=0){
                              coverlang = langsm[languages.indexOf(language)];
                          }
                        let url = cover+"/cover/"+coverlang+"/v"+index+".jpg";
                        return (
                            <Link key={ind} to ={language+"/bible/"+t.split(' ').join('-')+"/"+this.linkwigni(1,index)+"/0/0/0/1"}>
                            <div  className="view overlay bibleDiv hoverable ">
                                <img src = {url}  className="hoverable" />
                                <div className="mask flex-center waves-effect waves-light rgba-blue-strong">
                                    <p className="white-text text-center bodyfont1 p-2">{t}</p>
                                </div>
                                </div>
                                </Link>
                            
                        )

                    })}
                </div> 
               <Footer />
            </React.Fragment>
         );
    }
}
 
export default IndexPage;