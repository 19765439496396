import React, { Component } from "react";
import ModalofFulchapter from "./modalofFulchapter";
import Config from "../../settings/config.json";
import axios from "axios";
const $ = window.$;
class ContentOfVersions extends Component {
  state = {
    chapter : "",
    title: "",
    

  };
  getShareUrl=(m)=>{
    
    let url = window.location.href;
    url = url.split("/");
    let urlr= "";

    for(let i = 0; i<url.length; i++){
      if(i==8)url[i]=m;

      urlr +=url[i]+"/";
    }
    urlr = urlr.slice(0, -1); 
    return urlr;
  }
  getFullchapter = async (w,t,wigni,muxli)=>{
    $("#modalofullsearch").modal("show");
    const fullchapter = await this.hendleGetFullChapter(w,t)
    let chapter = fullchapter.map(b=>{
      return <div key={b.muxli} style={{color:muxli===b.muxli ? "red":"black"}}>{b.muxli} {b.mv}</div>
    })
    let title =  wigni + " "+t;
    this.setState({chapter,title})

  }
  httpCon = Config.httpconnection;
  hendleGetFullChapter = async (w,t) => {  
    let { data } =  await axios.get(
      this.httpCon + "?full=true&t="+t+"&w="+w+"&mv="+this.props.selectedVersion+"&language="+this.props.language
    );
   console.log(data);
    return data;
  }
  getNumberOfVersions = (headof)=>{
    let v=1;
    for(let i = 0; i<headof.length; i++){
      if(headof[i] != "")v++;
    }
    return v;
  }
  clearParalelVersion = (v)=>{
    $("#bibleVersions"+v).val("");
    $("#bibleVersions"+v).materialSelect();
    this.props.HendleSaveVersions()
  }
  getHeadOfVersion = (headof,vnum) => {
    
    if(vnum < 2) return;
    vnum = 12 / vnum;
    
      return ( 
             
        
            headof.map((vh,ind) =>{
              return vh != "" ? <div className={"col-lg-"+vnum} key={ind}> 
              <div className="chip mdb-color lighten-2 white-text waves-effect">
                       {vh}
                        <i className="close fas fa-times" onClick = {()=>this.clearParalelVersion(ind+1)}></i>
                      </div>
              </div>:""
            })
          
        
      );
  };
  getPages = (count,current,trans,gotopage)=>{
    current =  parseInt(current); 
    if(current === "" || current === 0){
      current = 1;
    }
    if(count <41) return "";
    count =  parseInt(count); 

    const pages = Math.ceil(count / 40);

    let nextpages =current +  5; 
    let prevpages =current -  5;
    
    if(nextpages > pages) nextpages = pages;
    if(prevpages < 1) prevpages = 1;
    let nextli = [];
    let prevli = [];
    for(let i=current+1; i<=nextpages; i++){
    nextli.push(<li key={"n"+i}  className="page-item"><a href="#" onClick={()=>gotopage(i)} className="page-link">{i}</a></li>);
      
    }
    for(let i=prevpages; i<current; i++){
      prevli.push(<li key={"p"+i} className="page-item"><a href="#" className="page-link"  onClick={()=>gotopage(i)}>{i}</a></li>);
        
      }
    return (
      <nav aria-label="Page navigation example">
  <ul className="pagination pg-blue pagination-lg flex-wrap">
    <li key={"ff1"} className={current == 1 ? "page-item disabled":"page-item"}><a href="#" className="page-link"  onClick={()=>gotopage(1)}><i className="fas fa-angle-double-left"></i></a></li>
    <li key={"fff1"} className={current == 1 ? "page-item disabled":"page-item"}><a href="#" className="page-link"  onClick={()=>gotopage(current-1)}><i className="fas fa-angle-left"></i></a></li>
    
   {prevli}    
    <li className="page-item active">
      <span className="page-link">
        {current} 
        <span className="sr-only">(current)</span>
      </span>
    </li>
    {nextli}
    <li key={"ppp1"} className={current === pages ? "page-item disabled":"page-item"}>
      <a className="page-link"  onClick={()=>gotopage(current+1)}><i className="fas fa-angle-right"></i></a>
    </li>
    <li key={"pp1"} className={current === pages ? "page-item disabled":"page-item"}><a href="#" className="page-link"  onClick={()=>gotopage(pages)}><i className="fas fa-angle-double-right"></i> {"("+pages+")"}</a></li>

  </ul>
</nav>
    )
  }

  getHeaderForReadBible=(searchText,wtm,bibleNames,tavi,changeHendle,themeclass,idbook,idchap)=>{
    
    if(window.innerWidth >1222)return;
    if(searchText == ""){ 
    let   selectedbook = bibleNames[parseInt(wtm.wigni)+2]
      return  <div className="text-center ">
        { this.getButtonForChangeChapter(tavi,wtm.tavi,"prev") } 
        <select className={"custom-select custom-select-md mb-3 "+themeclass} id={idbook} onChange={(e)=>changeHendle("bnameMob",e.target.value)} style={{width: "135px"}}>      
          {bibleNames.map((b,ind) => {
              return parseInt(ind) > 2 ? <option value={ind+1} key={b}  >{b}</option>: ""})
            }
        </select>
<select onChange={(e)=>this.props.changeChapter(e.target.value)} value={wtm.tavi} id={idchap} className={"custom-select custom-select-md mb-3 "+themeclass}  style={{width: "70px"}}>      
        {tavi.map((t) => {
           return   t !="" ?<option value={t} key={t}  >{t}</option>:"" })
            } 
</select>
          { this.getButtonForChangeChapter(tavi,wtm.tavi,"next") }
         </div> 
    }
    
  }
  //{bibleNames[parseInt(wtm.wigni)+2]} 
  getButtonForChangeChapter = (tavi, currenttavi,status)=>{
    let buttonget = 0;let iconf =""; let step = 0;
 
    if(status == "prev"){
      step = parseInt(currenttavi)-1;
      iconf =<i className="fas fa-chevron-left"></i>;
      if(currenttavi >1 )buttonget = 1;
    }else{
      
      step = parseInt(currenttavi)+1;
      iconf =<i className="fas fa-chevron-right"></i>;
      if(currenttavi < Math.max(...tavi))buttonget = 1;
    }
          
    return buttonget == 1 ? (<button type="button" onClick={() => this.props.changeChapter(step)} style={{marginTop: "-10px"}} className="btn btn-outline-grey btn-md lighten-5 waves-effect px-2">{iconf}</button>) :("")

  }
  versionsCheck = (t) => { 
    let { version1, version2, version3 } = this.props;
    let paralel1 = typeof(t.paralel1) != "undefined" ? t.paralel1 :"";
    let paralel2 = typeof(t.paralel2) != "undefined" ? t.paralel2 :"";
    let paralel3 = typeof(t.paralel3) != "undefined" ? t.paralel3 :"";
    
    if(version3 != ""){
      if(version2 ==""){
        paralel2= paralel3;
        version2= paralel3;
        paralel3 = version3 = "";

      }else if(version1 ==""){
        paralel1= paralel3;
        version1= version3;
        paralel3 = version3 = "";
      }
    }
  
    const text =(this.props.wtm["wigni"] < 40) ? this.props.trans["versiaSheicavs"]:this.props.trans["versiaSheicavs2"];
    t.bv =  t.bv == "" ? <span className="badge badge-pill badge-warning">{text}</span> : t.bv;
    paralel1 =  paralel1 == "" ? <span className="badge badge-pill badge-warning">{text}</span> : paralel1;
    paralel2 =  paralel2 == "" ? <span className="badge badge-pill badge-warning">{text}</span> : paralel2;
    paralel3 =  paralel3 == "" ? <span className="badge badge-pill badge-warning">{text}</span> : paralel3;
    if (version1 !="" &&  version2 !="" && version3 == "" ) {
      
      return (
        <div className="row" key={t.id}>
          <div className="col-lg-4">
            <div key={t.id} className="bible-text c">
              {t.muxli} {t.bv} 
            </div>
          </div>
          <div className="col-lg-4">
            <div key={t.id} className="bible-text bible-text-v2">
              {t.muxli} {paralel1}
            </div>
          </div>
          <div className="col-lg-4 ">
            <div key={t.id} className="bible-text bible-text-v3">
              {t.muxli} {paralel2}
            </div>
          </div>
        </div>
      );
    }
    if (version1 !="" &&  version2 !="" &&  version3 !="" ) {
      
      return (
        <div className="row" key={t.id}>
          <div className="col-lg-3">
            <div key={t.id} className="bible-text c">
              {t.muxli} {t.bv}
            </div>
          </div>
          <div className="col-lg-3">
            <div key={t.id} className="bible-text bible-text-v2">
              {t.muxli} {paralel1}
              <div></div>
            </div>
          </div>
          <div className="col-lg-3 ">
            <div key={t.id} className="bible-text bible-text-v3">
              {t.muxli} {paralel2}
            </div>
          </div>
          <div className="col-lg-3 ">
            <div key={t.id} className="bible-text bible-text-v4">
              {t.muxli} {paralel3}
            </div>
          </div>
        </div>
      );
    }
    if (version1 !="" &&  version2 =="") {       
      return (
        <div className="row" key={t.id}>
          <div className="col-lg-6">
            <div key={t.id} className="bible-text bible-text-v1">
              {t.muxli} {t.bv}
            </div>
          </div>
          <div className="col-lg-6">
            <div key={t.id} className="bible-text bible-text-v2">
              {t.muxli} {paralel1}
            </div>
          </div>
        </div>
      );
    }
 
    if (version1 =="" &&  version2 !="") {
      
      return (
        <div className="row" key={t.id}>
          <div className="col-lg-6">
            <div key={t.id} className="bible-text bible-text-v1">
              {t.muxli} {t.bv}
            </div>
          </div>
          <div className="col-lg-6">
            <div key={t.id} className="bible-text bible-text-v2">
              {t.muxli} {paralel2}
            </div>
          </div>
        </div>
      );
    }
    return (
     
      <div key={t.id} className="bible-text bible-text-v1">
        {t.muxli} {t.bv} <a href = {"https://www.facebook.com/sharer/sharer.php?u="+this.getShareUrl(t.muxli)} target="_blank"><i className="fas fa-share-alt"></i></a>
  
     </div>
    );
  };
  render() {
    let { version1, version2, version3,trans,page,pagecount,goToPage } = this.props;
    let arrofVersions=[ version1, version2, version3 ];
  
   
    const { bibleData, bibleNames, searchText, fontSize,wtm,tavi,changeHendle,CancelSearch,themeclass} = this.props;
    const headerofread = this.getHeaderForReadBible(searchText,wtm,bibleNames,tavi,changeHendle,themeclass,"bibleBookMobile1","bibleChapterMobile1");
    const headerofread1 = this.getHeaderForReadBible(searchText,wtm,bibleNames,tavi,changeHendle,themeclass,"bibleBookMobile2","bibleChapterMobile2");
   let  headOFVersions = "";
   let vnum = 0;
  
    if(searchText == "" ){
         
          vnum = this.getNumberOfVersions(arrofVersions); 
          if(vnum > 1)headOFVersions = this.getHeadOfVersion(arrofVersions,vnum);   
          if(vnum > 1) vnum = 12 / vnum;;
        
          
    }
    return (
      <div className={themeclass + " col-lg-12"} style={{ fontSize: fontSize + "px" }}>
        {headerofread}
        {
        
        <div className="row">{headOFVersions != "" ? <div className = {"col-lg-"+vnum}> <div className="chip mdb-color lighten-2 white-text waves-effect">{this.props.selectedVersion}</div></div>:""}{headOFVersions}</div>}
         {searchText != "" ?<p className="pl-1">{trans["moidzebna"]+" "+pagecount + " "+trans["chanaweri"]}</p>:""}
        {searchText != "" ?<button type="button" onClick={CancelSearch}  className="btn btn-outline-danger waves-effect btn-md headFont "> X {trans["dzebnisshewyveta"]}</button>:""}
        {searchText != "" ?<ModalofFulchapter chapter = {this.state.chapter} title = {this.state.title}  />:""}
       

        {        
        bibleData.map((t) => {
         
          return searchText == "" ? (
            this.versionsCheck(t)
          ) : (
            <div key={t.id} className="bible-text bible-text-v1 pb-3">
              
              <h4 className="pb-2 pt-2 font-weight-bold searchHeader"  onClick={()=>this.getFullchapter(t.wigni,t.tavi,bibleNames[parseInt(t.wigni) +2],t.muxli)}>
                {bibleNames[parseInt(t.wigni) +2]} {t.tavi}
              </h4>
              <span className="pr-3">{t.muxli}</span>
              <span dangerouslySetInnerHTML={{__html: t.bv}}> 
                 
                </span>
            </div>
          );
        })}
        <div style={{paddingTop:"15px"}}>{headerofread1}</div>
        <div>{searchText !== ""?this.getPages(pagecount,page,trans,goToPage):""}</div>
      </div>
    );
  }
}

export default ContentOfVersions;
/* {reactStringReplace(t.bv, searchText, (match, i) => (
                <span key={"marked" + i++} className="markedText">
                  {match}
                </span>
              ))}*/