import React, { Component } from "react";
import Header from "./readbible/header";
import Footer from "./readbible/footer";
import BodyReadBible from "./readbible/body";
import Config from "../settings/config.json";
import axios from "axios";



const $ = window.$;

class Main extends Component {
  state = {
    language: this.props.match.params.language !=="" && this.props.match.params.language  ? this.props.match.params.language : "geo",
    bibleNames: [],
    languages:[],
    bibleData: [],
    tavi: [],
    muxli: [""],
    wtm:[],
    langVersions:[],
    trans:[],
    loading: false,
    mainVersion: this.props.match.params.bersion ? this.props.match.params.bersion.split('-').join(' ') : "ახალი გადამუშავებული გამოცემა 2015",
    themeclass:"appTheme",
    page: typeof(this.props.match.params.page)  !== 'undefined' ? this.props.match.params.page : 1,
    pagecount:"",
    langsm:[]

  };
  Interval = null;
  httpCon = Config.httpconnection;
  searchText = "";
  loadingI = 0;
  searchtt ="";
  version = "";
  version1 = "";
  version2 = "";
  version3 = "";
  l1="";l2="";l3="";
  
  
  async componentDidMount() {
     await this.hendleGet(1,1,"","FirstLoadBibles");
  }
  onHendleChange = (e = "", bookval = "", chapterval = 1) => {
  
    
    clearInterval(this.Interval);
    this.Interval = null;
    this.loadingI = 0;
    if(e=="bname"){      
      
      $("#bibleHead").val(0);
      $("#bibleHead").materialSelect();
      $("#bibleVerse").val("");
      $("#bibleVerse").materialSelect();     
    }
 
    let w = "";
    let t = "";
    let m = "";
    let s = "";

   if(e=="bnameMob"){
    //"bibleBookMobile1","bibleChapterMobile1"
    $("#bibleBookMobile1").val(bookval);
    $("#bibleBookMobile2").val(bookval);
    $("#bibleChapterMobile1").val(chapterval);
    $("#bibleChapterMobile2").val(chapterval);
    w= bookval ;
    t = chapterval ;
    
        
    }else if($("#bibleBook").length){
       w = document.getElementById("bibleBook").value;
       t = document.getElementById("bibleHead").value;
       m = document.getElementById("bibleVerse").value;
       s = document.getElementById("bibleTextSearch").value;
    }else{
      w = document.getElementById("bibleBookMobile1").value;
      s = ""
      t=document.getElementById("bibleChapterMobile1").value;;
      m="";
    }



   
    //console.log(id)
  
    this.hendleGet(w, t, m, s);

  };

  goToPage =(p)=>{
    let w = "";
    let t = "";
    let m = "";
    let s = "";
    if($("#bibleBook").length){
      w = document.getElementById("bibleBook").value;
      t = document.getElementById("bibleHead").value;
      m = document.getElementById("bibleVerse").value;
      s = document.getElementById("bibleTextSearch").value;
   }else{
    w = this.mobilesearchbook;
    s = this.mobilesearchs;
     //w = document.getElementById("bibleBookMobile1").value;
  t="";
   //  t=document.getElementById("bibleChapterMobile1").value;;
     m="";
   }



  
   //console.log(id)
 
   this.hendleGet(w, t, m, s, p);

  }
  mobilesearchbook = "";
  mobilesearchs = "";
  onHendleChangMobile = () => {
    
    clearInterval(this.Interval);
    this.Interval = null;
    this.loadingI = 0;

 
    let w = "";
    let t = "";
    let m = "";
    let s = "";
  
        
      w = document.getElementById("mobilebiblebook").value;
      s = document.getElementById("bibleTextSearchMobile").value;
      t="";
      m="";

      this.mobilesearchbook = w;
      this.mobilesearchs = s;
    this.hendleGet(w, t, m, s);
  }

  changeStyle = (classn)=>{
    let themeclass = classn;
    this.setState({themeclass});
  }
  hendleInTextSearch = (mb) => {
    let s = "";
    if(mb == "mobile") s = document.getElementById("bibleTextSearchMobile").value;
    else s = document.getElementById("bibleTextSearch").value;

   
    clearInterval(this.Interval);
    this.Interval = null;
  
    this.searchText = s;
    if (s.length > 2 || s == "") {
      this.searchtt = s;
      this.Interval = setInterval(() => {
        if(mb == "mobile")  this.onHendleChangMobile();
        else this.onHendleChange();
      }, 2000);

      if (this.loadingI === 0) {
        this.setState({ loading: true });
      }
      this.loadingI++;
    }
  };
  hendleGet = async (w, t, m, s, p = "") => {  
    
  let page = this.state.page;
  let {bersion, book, chapter, verse, qstring} =this.props.match.params;

  if(p != "")page = p;
    let ss = s;
    
   if(s === "FirstLoadBibles") {
     s="";
      w = book;
      t=chapter;
      m = verse==="0" ? "0" : verse;
      s = qstring === "0" ? "" : qstring
      this.searchText = s
     

    }  else{
      
      let book = w===""?4:w;
      let chapter = t===""?1:t;
      let verse = m===""?0:m;
     let    qstring = s===""?0:s;
    
      let url = ""+this.state.language+"/bible/"+bersion+"/"+book+"/"+chapter+"/"+verse+"/"+qstring+"/"+page;
      window.history.pushState([], "", "/")
      window.history.pushState([], "", url)
    }
  //console.log( this.httpCon + "?w=" + w + "&t=" + t + "&m=" + m + "&s=" + s+"&v1="+this.version1+"&v2="+this.version2+"&v3="+this.version3+"&l1="+this.l1+"&l2="+this.l2+"&l3="+this.l3+"&mv="+this.state.mainVersion+"&language="+this.state.language
  //);

    let { data } = await axios.get(
      this.httpCon + "?w=" + w + "&t=" + t + "&m=" + m + "&s=" + s+"&v1="+this.version1+"&v2="+this.version2+"&v3="+this.version3+"&l1="+this.l1+"&l2="+this.l2+"&l3="+this.l3+"&mv="+this.state.mainVersion+"&language="+this.state.language+"&page="+page

    );

    const bibleData = [...data.bibleData];
    const langVersions = data.versions;
    const bibleNames = [...data.bibleNames];
    const wtm = data.wtm;
    const languages = data.languages;
    const trans = data.trans;
    const langsm = data.langsm;
    const pagecount = data.pagecount !== "" ? data.pagecount[0]["cc"]:0;
 
    let muxli = [""];
    let tavi = [""];
    for (let i = 1; i <= parseInt(data.muxli[0].cc); i++) muxli.push(String(i));
    for (let i = 1; i <= parseInt(data.tavi[0].cc); i++) tavi.push(String(i));
  
     
    if(ss == "FirstLoadBibles"){
      this.setState({
        bibleNames,
        bibleData,
        muxli,        
        version1: this.version1 ? this.version1 : "",
        version2: this.version2 ? this.version2 : "",
        version3: this.version3 ? this.version3 : "",
        trans: trans,
        tavi,
        langVersions,
        wtm,
        languages,
        langsm,
        pagecount,
        page
      },function(){
        window.scrollTo(0, 0)
        $('.mdb-select').materialSelect();
        if(window.innerWidth >= 1222){

          $("#bibleBook").val(w);
        
          $("#bibleHead").val(t == 1 ? 0 : t);
        
          $("#bibleVerse").val(m);         
  
          $("#bibleTextSearch").val(s);
         
        }else{
          $("#bibleBookMobile1").val(w<4?4:w);
          $("#bibleBookMobile2").val(w<4?4:w);
          $("#bibleChapterMobile1").val(t==0? 1:t);
          $("#bibleChapterMobile2").val(t==0? 1:t);
        }
       
      });
    }else{
     
      this.setState({
        loading: false,
        bibleData,      
        wtm,
        tavi,
        muxli,
        pagecount,
        page
     
      },function(){
     
        window.scrollTo(0, 0)
      });
    }

   
  };

  onchangeChapter = (chapter) => {
    $("#bibleChapterMobile1").val(chapter);
    $("#bibleChapterMobile2").val(chapter);
    this.onHendleChange("bnameMob",$("#bibleBookMobile1").val(),chapter);

    
   
  };
  addVersions = (value, id) => {
   // document.getElementById(id).value = value;
  };

  changeBibleVersion = (value, id) => {   
    if(value == "saveVersion"){
      
     if(this.version !=""){
       let mainVersion = $("#"+id).val()
     
       this.setState({ mainVersion }, () => {
        this.onHendleChange();
      });
     
     }     
    
     $("#modalOfSideBar").modal("hide");
    }else{
      this.version = value;
      
    }
   
  };
  HendleSaveVersions = () => {
    let v1 = document.getElementById("bibleVersions1").value;
    let v2 = document.getElementById("bibleVersions2").value;
    let v3 = document.getElementById("bibleVersions3").value;
    let lang1 = document.getElementById("versionLanguage1").value;
    let lang2 = document.getElementById("versionLanguage2").value;
    let lang3 = document.getElementById("versionLanguage3").value;
    this.version1 = v1;
    this.version2 = v2;
    this.version3 = v3;
    this.l1 = lang1;
    this.l2 = lang2;
    this.l3 = lang3;
    $("#modalOfVersions").modal("hide");
    this.onHendleChange("", "", "");
  };
  CancelSearch = ()=>{
   if($("#bibleTextSearch").length){
     document.getElementById("bibleTextSearch").value="";
     this.hendleInTextSearch("");
    }else{
      document.getElementById("bibleTextSearchMobile").value="";
      this.hendleInTextSearch("mobile");
    }
   
    
  }
  render() {
   
    return (
      <React.Fragment>
        <Header  languages ={this.state.languages} trans={this.state.trans} langsm = {this.state.langsm} language = {this.state.language} />
        <BodyReadBible
          bibleNames={this.state.bibleNames}
          tavi={this.state.tavi}
          muxli={this.state.muxli}
          changeHendle={this.onHendleChange}
          bibleData={this.state.bibleData}
          wtm={this.state.wtm}
          langVersions={this.state.langVersions}        
          hendleInTextSearch={this.hendleInTextSearch}
          searchText={this.searchText}
          loading={this.state.loading}         
          onVersionHendle={this.addVersions}
          HendleSaveVersions={this.HendleSaveVersions}
          version1={this.version1}
          version2={this.version2}
          version3={this.version3}
          pagecount={this.state.pagecount}
          page={this.state.page}
          selectedVersion={this.state.mainVersion}
          changeChapter={this.onchangeChapter}
          changeBibleVersion={this.changeBibleVersion}
          CancelSearch={this.CancelSearch}
          themeclass={this.state.themeclass}
          changeStyle={this.changeStyle}
          trans={this.state.trans}
          language={this.state.language}
          goToPage = {this.goToPage}
        />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Main;
