import React, { Component } from "react";
import Config from "../../settings/config.json";
import axios from "axios";
const $ = window.$;

class ModalOfVersions extends Component {
  state = {
    langs: [],
    lang1:"ქართული",
    lang2:"ქართული",
    lang3:"ქართული",
    versions1: [],
    versions2: [],
    versions3: [],
    
  };

  async componentDidMount() {
  let  langs = await this.hendleGetLang()
  let  versions1 = await this.hendleGetVersions(this.state.lang1) 
  let  versions2 = await this.hendleGetVersions(this.state.lang2) 
  let  versions3 = await this.hendleGetVersions(this.state.lang3) 
  this.setState({langs, versions1,versions2,versions3});
  }
 
  httpCon = Config.httpconnection;
  hendleGetLang = async () => {  
    let { data } = await axios.get(
      this.httpCon + "?language=1"
    );
   
    return data.langs;
  }

  hendleGetVersions = async (lang) => {
    
    let { data } = await axios.get(
      this.httpCon + "?versionslang="+lang
    );
  
    return  data.versions;
  }

  hendleLanguageChange = async () => {
    const language = $("#versionLanguage1").val();  
    const versions1 = await this.hendleGetVersions(language);   
    this.setState({versions1})  
     
  }
  hendleLanguageChange2 = async () => {
    const language = $("#versionLanguage2").val();  
    const versions2 = await this.hendleGetVersions(language);    
    this.setState({versions2})   
    
  }
  hendleLanguageChange3 = async () => {
    const language = $("#versionLanguage3").val();  
    const versions3 = await this.hendleGetVersions(language); 
    this.setState({versions3})       
  }


  render() {
   const {trans} = this.props; 
    return (
      
      <div
        className="modal fade"
        id="modalOfVersions"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-notify modal-info" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <p className="heading lead headFont">{trans["SeadareTargmani"]} </p>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className="white-text">
                  &times;
                </span>
              </button>
            </div>

            <div className="modal-body">
              <div className="text-center">
                <h5 className="headFont">{trans["SeadareTargmani"]} 1</h5>  
                <label className="mdb-main-label bodyfont1">{trans["airchieena"]} 1</label> 
                <select className="browser-default custom-select  bodyfont1" id="versionLanguage1" onChange={this.hendleLanguageChange} >
                  {this.state.langs.map((b,ind)=>{
                    return (b != "") ?<option key={ind} value = {b}>{b}</option>:""
                  })
                }
                </select>
                
                <div className="mt-3">
                <label className="mdb-main-label bodyfont1">{trans["airchieversia"]} 1</label>
                <select  className="browser-default custom-select bodyfont1" id="bibleVersions1" >
                <option key="01" value = ""></option>
                  {this.state.versions1.map((b,ind)=>{
                    return <option key={ind} value = {b}>{b}</option>
                  })
                }
                </select>
                
                </div>
                <div className="mt-5">
               
                  <h5 className="headFont">{trans["SeadareTargmani"]} 2</h5>
                  <label className="mdb-main-label bodyfont1">{trans["airchieena"]} 2</label>
                  <select className="browser-default custom-select bodyfont1" id="versionLanguage2" onChange={this.hendleLanguageChange2} searchable="ძიება..." data-visible-options="10">
                  {this.state.langs.map((b,ind)=>{
                    return (b != "") ?<option key={ind} value = {b}>{b}</option>:""
                  })
                }
                </select>
                
                </div>
                <div className="mt-3">
                <label className="mdb-main-label bodyfont1">{trans["airchieversia"]} 2</label>
                <select className="browser-default custom-select bodyfont1" id="bibleVersions2"  data-visible-options="10">
                <option key="02" value = ""></option>
                  {this.state.versions2.map((b,ind)=>{
                    return <option key={ind} value = {b}>{b}</option>
                  })
                }
                </select>
                
                </div>
                <div className="mt-5">
                  <h5 className="headFont">{trans["SeadareTargmani"]} 3</h5>
                  <label className="mdb-main-label bodyfont1">{trans["airchieena"]} 3</label>
                  <select className="browser-default custom-select bodyfont1" id="versionLanguage3"  onChange={this.hendleLanguageChange3} searchable="ძიება..." data-visible-options="10">
                  {this.state.langs.map((b,ind)=>{
                    return (b != "") ?<option key={ind} value = {b}>{b}</option>:""
                  })
                }
                </select>
                
                </div>
                <div className="mt-3">
                <label className="mdb-main-label bodyfont1">{trans["airchieversia"]} 3</label>
                <select className="browser-default custom-select bodyfont1" id="bibleVersions3"   data-visible-options="10">
                  <option key="03" value = ""></option>
                  {this.state.versions3.map((b,ind)=>{
                    return <option key={ind} value = {b}>{b}</option>
                  })
                }
                </select>
                
                </div>
                </div>
            </div>

            <div className="modal-footer justify-content-center">
              <a
                type="button"
                onClick={this.props.HendleSaveVersions}
                className="btn btn-info headFont"
              >
                არჩევა
              </a>
              <a
                type="button"
                className="btn btn-outline-info headFont waves-effect"
                data-dismiss="modal"
              >
                {trans["close"]}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalOfVersions;
