import React, { Component } from "react";
class ModalOfSideBar extends Component {
  state = {
    
  };
  componentDidMount() {
    document.getElementById("fontRadio1").checked = true;
    document.getElementById("fontRadio4").checked = true;
  }
  render() {
    const {trans} = this.props;
    return (
      <div
        className="modal fade right"
        id="modalOfSideBar"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalPreviewLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-full-height modal-right"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title headFont"
                id="exampleModalPreviewLabel"
              >
                {trans["settings"]}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
        
                      <h5 className="headFont fonttitle" >{trans["parametrebi"]}</h5>
                      <div className="custom-control custom-radio bodyfont">
                        <input type="radio" className="custom-control-input" onClick ={(e)=>this.props.fontchange(1,e.target)}  id="fontRadio1" name="groupOfDefaultRadios" />
                        <label className="custom-control-label" htmlFor="fontRadio1">{trans["biblia"]}</label>
                      </div>


                      <div className="custom-control custom-radio bodyfont2">
                        <input type="radio" className="custom-control-input" onClick ={(e)=>this.props.fontchange(2,e.target)}  id="fontRadio2" name="groupOfDefaultRadios"  />
                        <label className="custom-control-label" htmlFor="fontRadio2">{trans["biblia"]}</label>
                      </div>


                      <div className="custom-control custom-radio bodyfont3">
                        <input type="radio" className="custom-control-input" onClick ={(e)=>this.props.fontchange(3,e.target)}  id="fontRadio3" name="groupOfDefaultRadios" />
                        <label className="custom-control-label " htmlFor="fontRadio3">{trans["biblia"]}</label>
                      </div>


                      <h5 className="headFont fonttitle" >{trans["airchieferi"]}</h5>
                      <div className="custom-control custom-radio bodyfont">
                        <input type="radio" className="custom-control-input" onClick ={()=>this.props.changeStyle("appTheme")}  id="fontRadio4" name="groupColor" />
                        <label className="custom-control-label appTheme p-2" htmlFor="fontRadio4">{trans["biblia"]}</label>
                      </div>


                      <div className="custom-control custom-radio bodyfont2">
                        <input type="radio" className="custom-control-input" onClick ={()=>this.props.changeStyle("appTheme1")}  id="fontRadio5" name="groupColor"  />
                        <label className="custom-control-label appTheme1 p-2" htmlFor="fontRadio5">{trans["biblia"]}</label>
                      </div>


                      <div className="custom-control custom-radio bodyfont3">
                        <input type="radio" className="custom-control-input" onClick ={()=>this.props.changeStyle("appTheme2")}   id="fontRadio6" name="groupColor" />
                        <label className="custom-control-label appTheme2 p-2" htmlFor="fontRadio6">{trans["biblia"]}</label>
                      </div>
                      <div className="custom-control custom-radio bodyfont3">
                        <input type="radio" className="custom-control-input" onClick ={()=>this.props.changeStyle("appTheme3")}   id="fontRadio7" name="groupColor" />
                        <label className="custom-control-label appTheme3 p-2" htmlFor="fontRadio7">{trans["biblia"]}</label>
                      </div>
                  </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {trans["close"]}
              </button>
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalOfSideBar;
/*    <select className="mdb-select md-form bodyfont1" id="bibleVersionId" onChange={this.props.changeBibleVersion} searchable="ძიება..." data-visible-options="10">
                  {this.props.langVersions.map((b,ind)=>{
                    return (b != "") ?<option key={ind} value = {b}>{b}</option>:""
                  })
                }
                </select>*/