
import React, { Component } from "react";
import {Route} from 'react-router-dom';
import {Switch} from 'react-router-dom';
import Main from "./pages/main.jsx";
import IndexPage from './pages/indexpage/indexpage';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-68997948-2');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  state = {  }
  render() { 
    return (  
    <div>
      <Switch>
        
        <Route path="/:language/bible/:bersion/:book/:chapter/:verse/:qstring/:page" component={Main} />       
        <Route path="/:language" component={IndexPage} />
        <Route path="" component={IndexPage} />
      </Switch>
    </div>
    
    
    );
  }
}
 
export default App;

