import React, { Component } from "react";
import Search from "./search";
import LoadingForSearch from "./loading";
import BodyBibleText from "./bodyBibleText";
import ModalOfVersions from "./modalOfversions";
import ModalOfSearch from "./modalOfSearch";
import ModalOfSideBar from "./modalOfSideBar";
class BodyReadBible extends Component {
  state = {
    fontSize: 17,
    bodyfont: "bodyfont1",
    n:1
  };
  handleFontMakeBigger = () => {
    let fontSize = this.state.fontSize;
    if (fontSize < 25) fontSize++;
    this.setState({ fontSize });
  };
  handleFontMakeSmoller = () => {
    let fontSize = this.state.fontSize;
    if (fontSize > 16) fontSize--;
    this.setState({ fontSize });
  };
  fontchange = (n,el) =>{
    const bodyfont = "bodyfont"+n;
    el.checked=true;
    this.setState({bodyfont,n});
  }
  getConditional = () => {
 
    
    const {
      bibleData,
      bibleNames,
      searchText,
      loading,
      version1,
      version2,
      version3,
      tavi,
      wtm,
      changeChapter,
      changeHendle,
      CancelSearch,
      selectedVersion,
      themeclass,
      HendleSaveVersions,
      trans,
      language,
      page,
      pagecount,
      goToPage
      
    } = this.props;

    if (!loading)
      return (
        
        <BodyBibleText
          bibleData={bibleData}
          bibleNames={bibleNames}
          searchText={searchText}
          loading={loading}
          version1={version1}
          version2={version2}
          version3={version3}
          wtm={wtm}
          tavi={tavi}
          fontSize={this.state.fontSize}
          changeChapter={changeChapter}
          changeHendle = {changeHendle}
          CancelSearch={CancelSearch}
          selectedVersion={selectedVersion}
          bodyfont={this.state.bodyfont}
          themeclass={themeclass}
          HendleSaveVersions={HendleSaveVersions}
          trans = {trans}
          language={language}
          page={page}
          pagecount={pagecount}
          goToPage = {goToPage}
        
          
        />
        
      );
    else 
    {
     
      return <LoadingForSearch />
    }
    
  };
  render() {
    const {
      tavi,
      muxli,
      bibleNames,
      changeHendle,
      versions,
      onVersionHendle,
      HendleSaveVersions,
      langVersions,
      selectedVersion,
      changeBibleVersion,
      themeclass,
      changeStyle,
      trans
    } = this.props;
    
    return (
      <React.Fragment>
        <ModalOfVersions
          versions={versions}
          onVersionHendle={onVersionHendle}
          HendleSaveVersions={HendleSaveVersions}  
          trans = {trans}        
        />
        <ModalOfSearch 
          bibleNames= {bibleNames} 
          hendleInTextSearch={this.props.hendleInTextSearch}
          trans={trans}
          
          />
        <ModalOfSideBar
        langVersions={langVersions}
        selectedVersion={selectedVersion}
        changeBibleVersion={changeBibleVersion}
        fontchange={this.fontchange}
        changeStyle= {changeStyle}
        trans = {trans}
        />
        <Search
          bibleNames={bibleNames}
          tavi={tavi}
          muxli={muxli}
          themeclass={themeclass}
          changeHendle={changeHendle}
          handleFontMakeBigger={this.handleFontMakeBigger}
          handleFontMakeSmoller={this.handleFontMakeSmoller}
          hendleInTextSearch={this.props.hendleInTextSearch}
          trans={trans}
        />
        {this.getConditional()}
      </React.Fragment>
    );
  }
}

export default BodyReadBible;
