import React, { Component } from "react";
import {Link} from 'react-router-dom';

class Header extends Component {
  state = {};
  detectlang = (language)=>{
    let lang=language;            
   
  if(language.length < 4) lang = this.props.languages[this.props.langsm.indexOf(language)];
      return lang;
  }
  render() {
    const {trans, langsm,language,languages} = this.props;
    return (
      //040926 1c2335
      <header className="position-fixed" style={{zIndex: 100}}>
        <nav className="navbar navbar-expand-lg navbar-dark headFont">
          <Link  className="navbar-brand" to={"/"+language} >
          <span className="logoimg"><img src="/hblogowhite.png"  /></span> 
            <span className="logot">HolyBible.ge</span>
          </Link>
          
 <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item active">
              <Link  className="navbar-brand" to={"/"+language} >
                  {trans["mtavari"]} <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className="nav-item">
              <a  className="navbar-brand" href="http://audio.holybible.ge" >
                  {trans["audiobible"]} 
                </a>
              </li>
              <li className="nav-item ">
              <a  className="navbar-brand" href="http://blog.holybible.ge" >
                  {trans["blog"]} 
                </a>
              </li>
              <li className="nav-item ">
              <a  className="navbar-brand" href="http://old.holybible.ge" >
                  {trans["oldversion"]}
                </a>
              </li>
     
            </ul>
          </div>
          <div className="dropdown">


<a className="btn btn-indigo dropdown-toggle btn-md " type="button" id="dropdownMenu2" data-toggle="dropdown"
  aria-haspopup="true" aria-expanded="false">{this.detectlang(this.props.language)}</a>



<div className="dropdown-menu dropdown-primary">
  {
    this.props.languages.map((a,ind)=>{
      return ( <a className="dropdown-item" key={ind} href={"/"+languages[ind]}>{a}</a> )
    }
    )}
  
</div>
</div>
<button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </nav>
      </header>
    );
  }
}

export default Header;
/*
*/