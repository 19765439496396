import React, { Component } from "react";
import ContentOfVersions from "./contentOfVersions";

class BodyBibleText extends Component {
  state = {
   

  };
 
  render() {
    const {
      bibleData,
      bibleNames,
      searchText,
      fontSize,
      version1,
      version2,
      version3,
      tavi,
      wtm,
      changeChapter,
      changeHendle,
      CancelSearch,
      HendleSaveVersions,
      selectedVersion,
      themeclass,
      trans,
      page,
      pagecount,
      language,
      goToPage
    } = this.props;
    return (
      <div className={"container-fluid "+ this.props.bodyfont +" main-body-text " + themeclass} >
        <div className={"p-2 " + themeclass}>
          <div className="row">
            <ContentOfVersions
              bibleData={bibleData}        
              bibleNames={bibleNames}
              searchText={searchText}
              version1={version1}
              version2={version2}
              version3={version3}
              fontSize={fontSize}
              tavi={tavi}
              wtm={wtm}
              changeChapter={changeChapter}
              changeHendle={changeHendle}
              CancelSearch={CancelSearch}
              selectedVersion={selectedVersion}
              themeclass={themeclass}
              HendleSaveVersions={HendleSaveVersions}
              trans={trans}
              language = {language}
              page={page}
              pagecount={pagecount}
              goToPage = {goToPage}
             
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BodyBibleText;
