import React, { Component } from "react";

class Footer extends Component {
  state = {};
  render() {
    return (
      //040926 1c2335
     
<footer className="page-footer font-small indigo">
  <div className="footer-copyright text-center py-3">© 2013-2021 Copyright:
    <a href="https://holybible.ge/"> holybible.ge</a>
  </div>


</footer>

    );
  }
}

export default Footer;