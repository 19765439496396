import React, { Component } from "react";



class Search extends Component {
  state = {};
  
   getDesktopSearch = ()=>{
    if(window.innerWidth <1222)return;
    let { bibleNames, tavi, muxli,changeHendle,themeclass,trans } = this.props;
    return (
        <div className="col-md-8 searchContent">
        <div className="row ">
          <div className="col-lg-4">
            <div className="pt-4" >
            
            <select className={"custom-select custom-select-md mb-3 bodyfont1 "+themeclass} id="bibleBook" onChange={()=>changeHendle("bname")} >
              {bibleNames.map((b,ind)=>{
                return <option key={ind} value = {ind+1}>{b}</option>
              })
            }
            </select>
            
            </div>
          </div>
          <div className="col-lg-2">
          <div className="pt-4" >
            <select className={"custom-select custom-select-md mb-3 bodyfont1 "+themeclass}  id="bibleHead" onChange={changeHendle} >
              <option key="0" value = "0">{trans["tavi"]}</option>
              {tavi.map((b,ind)=>{
                return (b !== "") ?<option key={ind} value = {ind}>{b}</option>:""
              })
            }
            </select>
            
            </div>
          </div>
          <div className="col-md-2">
          <div className="pt-4" >
            <select className={"custom-select custom-select-md mb-3 bodyfont1 "+themeclass}  onChange={changeHendle} id="bibleVerse"  > 
            <option key="0" value = "0">{trans["muxli"]}</option>
              {muxli.map((b,ind)=>{
                return b !== "" ?<option key={ind +1} value = {ind}  >{b}</option>:""
              })
            }
            </select>
            
            </div>
          </div>
          <div className="col-lg-4">
            
            <div className="md-form form-md  input-with-post-icon ">
              <i className="fas fa-search input-prefix"></i>
              <input
                type="text"
                id ="bibleTextSearch"
                className="form-control bodyfont1"
                onKeyUp={()=>this.props.hendleInTextSearch("")}
                placeholder = {trans["dzebna"]} 
              />
              
            </div>
          </div>
        </div>
      </div>

    )
}
  render() {
    let { themeclass } = this.props;
  
    return (
      <div className={themeclass + " container-fluid search-panel position-fixed shadow-sm "}>        
        <div className="row">          
         {this.getDesktopSearch()}
          <div className="col">
            <div className="float-right icons-read">
              <button
                 style={{
                 fontSize: "24px",
                 
                }}
                className  ={themeclass +"  searchIcon searchButtonNav"}
                data-toggle="modal"
                data-target="#modalOfSearch"
              >
                <i className="fas fa-search "></i>
              </button>
              <span className="mDivider searchIcon">
                |
              </span>
              <a
                className={themeclass +" searchButtonNav "}
                style={{
                  fontSize: "24px",
                }}
                data-toggle="modal"
                data-target="#modalOfVersions "
              >
                <i className="fas fa-bible"></i>+
              </a>
              <span className="mDivider" >
                |
              </span>
              <a
               className={themeclass +" searchButtonNav "}
                onClick={()=>this.props.handleFontMakeBigger()}
                style={{                 
                  fontSize: "26px",
                }}
              >
                <i className="fas fa-font"></i>+
              </a>
              <a
                className={themeclass +" searchButtonNav "}
                onClick={()=>this.props.handleFontMakeSmoller()}
                style={{                
                  fontSize: "22px",
                }}
              >
                <i className="fas fa-font"></i>-
              </a>
              <span className="mDivider" >
                |
              </span>
              <a
               className={themeclass +" searchButtonNav "}
                data-toggle="modal"
                data-target="#modalOfSideBar"
                style={{
                  fontSize: "30px",
                }}
              >
                <i className="fas fa-book-reader"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Search;
