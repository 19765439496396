import React, { Component } from "react";
class ModalOfSideBar extends Component {
  state = {};
  render() {
    return (


<div className="modal fade"  id="modalofullsearch" tabIndex="-1" role="dialog" aria-labelledby="exampleModalPreviewLabel"  aria-hidden="true">
<div className="modal-dialog modal-fluid" role="document">
  <div className="modal-content">
    <div className="modal-header">
      <h5 className="modal-title headFont " id="headoffullChapter" >{this.props.title}</h5>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body" id="bodyoffullChapter">
    {this.props.chapter}
    </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-secondary headFont " data-dismiss="modal">დახურვა</button>
      
    </div>
  </div>
</div>
</div>
    );
  }
}

export default ModalOfSideBar;
